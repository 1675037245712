import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./about.scss";
import fasilitasimages from "../../asset/fasilitas/fasilitas";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const about = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1,
          arrows: true, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  const waabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282114609687&text=Halo+William%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(about)%20http://marketing-giantaraserpongcity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="about">
      <div id="about" className="container-about">
        <div className="deskripsi">
          <div className="judul-about">
            <FontAwesomeIcon icon={faHouse} color="#bd9e24" />
            &nbsp; Giantara Serpong City
          </div>
          <div className="deskripsi-about">
            Dengan luas 109 hektar, Giantara Serpong City adalah kawasan mandiri
            baru di Cisauk, Tangerang. Terletak hanya 300 meter dari pintu tol
            ke-4 BSD City dan hanya satu menit dari Stasiun KA Cicayur, kota ini
            menawarkan ruko, pasar modern, mal luar ruangan, dan ruang
            komunitas. Rumah ukuran 5x12 dan 6x12 tersedia, dan harga jualnya
            mulai dari Rp 1.046 M-an* dan Rp 1,2 milyaran*, masing-masing.
            Giantara Group, yang didirikan pada tahun 1988, telah berubah dari
            bekerja di tambang pasir menjadi mengembangkan properti, dan
            hasilnya adalah Kota Giantara Serpong.
          </div>
          <div className="button-whatsapp">
            <button className="whatsapp" onClick={waabout}>
              WhatsApp
            </button>
          </div>
        </div>
        <div className="slider-fasilitas">
          <div className="fasilitas">
            <Slider {...settings}>
              {fasilitasimages.map((image, index) => (
                <div className="parent">
                  <img
                    className="image-logo"
                    key={index}
                    src={image}
                    alt="foto logo"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
