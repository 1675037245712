import Concept1 from "./6.webp";
import Concept2 from "./7.webp";
import Concept3 from "./8.webp";
import Concept4 from "./9.webp";
import Concept5 from "./10.webp";
import Concept6 from "./11.webp";
import Concept7 from "./12.webp";
import Concept8 from "./13.webp";

const conceptimages = [
  Concept1,
  Concept2,
  Concept3,
  Concept4,
  Concept5,
  Concept6,
  Concept7,
  Concept8,
];

export default conceptimages;
