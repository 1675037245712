import Giantaral61 from "./giantaral6 (1).webp";
import Giantaral62 from "./giantaral6 (2).webp";
import Giantaral63 from "./giantaral6 (3).webp";
import Giantaral64 from "./giantaral6 (4).webp";
import Giantaral65 from "./giantaral6 (5).webp";
import Giantaral66 from "./giantaral6 (6).webp";
import Giantaral67 from "./giantaral6 (7).webp";
import Giantaral68 from "./giantaral6 (8).webp";
import Giantaral69 from "./giantaral6 (9).webp";

const giantaral6images = [
  Giantaral61,
  Giantaral62,
  Giantaral63,
  Giantaral64,
  Giantaral65,
  Giantaral66,
  Giantaral67,
  Giantaral68,
  Giantaral69,
];

export default giantaral6images;
