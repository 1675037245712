import React from "react";
import "./home.scss";
import Navbar from "../../section/navbar/navbar.jsx";
import Header from "../../section/header/header.jsx";
import About from "../../section/about/about.jsx";
import Promo from "../../section/promo/promo.jsx";
import Produk from "../../section/produk/produk.jsx";
import Lokasi from "../../section/lokasi/lokasi.jsx";
import Nerin from "../../section/nerin/nerin.jsx";
import Khione from "../../section/khione/khione.jsx";
import Concept from "../../section/concept/concept.jsx";
import Footer from "../../section/footer/footer.jsx";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Header />
      <About />
      <Promo />
      <Produk />
      <Nerin />
      <Khione />
      <Concept />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default home;
