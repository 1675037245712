import React from "react";
import "./concept.scss";
import masterplan from "../../asset/concept/masterplan.webp";
import conceptimages from "../../asset/concept/concept";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import todimages from "../../asset/concept/tod/tod";
import podimages from "../../asset/concept/pod/pod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoad } from "@fortawesome/free-solid-svg-icons";
import { faEnvira } from "@fortawesome/free-brands-svg-icons";

const concept = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  const settings1 = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  return (
    <div className="concept">
      <div className="container-concept">
        <div className="container1">
          <div className="keterangan-concept">
            <div className="judul">Wholesome and Integrated Living</div>
          </div>
          <div className="container-content">
            <div className="image-masterplan">
              <img className="masterplan" src={masterplan} alt="" />
            </div>
            <div className="carousel-concept">
              {" "}
              <Slider {...settings}>
                {conceptimages.map((image, index) => (
                  <img
                    className="kartugambar"
                    key={index}
                    src={image}
                    alt="foto"
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="container2">
          <div className="container-tod">
            <div className="keterangan-tod">
              <div className="judultod">
                <FontAwesomeIcon icon={faRoad} />
                &nbsp; Transit Oriented Developmet
              </div>
              <div className="desktod">
                Transit Oriented Development (TOD) adalah ide pengembangan kota
                yang menggabungkan desain ruang kota untuk menyatukan orang,
                kegiatan, bangunan, dan ruang publik melalui konektivitas yang
                mudah dengan berjalan kaki atau bersepeda dan dekat dengan
                layanan angkutan umum yang baik ke seluruh kota. TOD berfokus
                pada pengembangan kawasan di sekitar simpul transit seperti
                stasiun kereta api, terminal bus, atau bandara untuk menciptakan
                lingkungan yang ramah lingkungan.
              </div>
            </div>
            <div className="carousel-tod">
              <Slider {...settings1}>
                {todimages.map((image, index) => (
                  <img
                    className="kartugambartod"
                    key={index}
                    src={image}
                    alt="foto"
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="container3">
          <div className="container-pod">
            <div className="keterangan-pod">
              <div className="judulpod">
                <FontAwesomeIcon icon={faEnvira} />
                &nbsp; Pedestrian Oriented Development
              </div>
              <div className="deskpod">
                Dengan tujuan meningkatkan aksesibilitas dan kenyamanan pejalan
                kaki di kawasan perkotaan, Pedestrian Oriented Development (POD)
                melibatkan desain ruang yang memprioritaskan pejalan kaki,
                seperti membangun jaringan jalan setapak yang luas, fasilitas
                umum yang dekat dengan tempat tinggal, dan integrasi sistem
                transportasi umum yang efisien. Tujuan POD adalah untuk
                mengurangi ketergantungan pada kendaraan pribadi, mengurangi
                kemacetan, dan meningkatkan kualitas hidup masyarakat dengan
                menggunakan moda transportasi umum yang lebih efisien dan ramah
                lingkungan.
              </div>
            </div>
            <div className="carousel-pod">
              <Slider {...settings1}>
                {podimages.map((image, index) => (
                  <img
                    className="kartugambar"
                    key={index}
                    src={image}
                    alt="foto"
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default concept;
