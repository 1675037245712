import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./navbar.scss";

const navbar = () => {
  return (
    <div className="navbar">
      <div className="container-navbar">
        <div className="menu-website">
          <ul>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#promo">Promo</a>
            </li>
            <li>
              <div class="dropdown">
                <button class="dropbtn">Cluster</button>
                <div class="dropdown-content">
                  <a href="#khione">
                    <FontAwesomeIcon icon={faGem} /> &nbsp; Khione
                  </a>
                  <a href="#nerin">Nerin</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default navbar;
