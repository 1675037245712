import Elyone1 from "./elyone (1).webp";
import Elyone2 from "./elyone (2).webp";
import Elyone3 from "./elyone (3).webp";
import Elyone4 from "./elyone (4).webp";
import Elyone5 from "./elyone (5).webp";
import Elyone6 from "./elyone (6).webp";
import Elyone7 from "./elyone (7).webp";
import Elyone8 from "./elyone (8).webp";
import Elyone9 from "./elyone (9).webp";
import Elyone10 from "./elyone (10).webp";
import Elyone11 from "./elyone (11).webp";
import Elyone12 from "./elyone (12).webp";
import Elyone13 from "./elyone (13).webp";
import Elyone14 from "./elyone (14).webp";
import Elyone15 from "./elyone (15).webp";
import Elyone16 from "./elyone (16).webp";
import Elyone17 from "./elyone (17).webp";

const elyoneimages = [
  Elyone1,
  Elyone2,
  Elyone3,
  Elyone4,
  Elyone5,
  Elyone6,
  Elyone7,
  Elyone8,
  Elyone9,
  Elyone10,
  Elyone11,
  Elyone12,
  Elyone13,
  Elyone14,
  Elyone15,
  Elyone16,
  Elyone17,
];

export default elyoneimages;
