import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./produk.scss";

const produk = () => {
  return (
    <div className="produk">
      <div className="container-produk">
        <div className="container-desk">
          <div className="judul">Our Home</div>
          <div className="desk-judul">
            Giantara Serpong City memiliki beberapa cluster yang menawarkan
            hunian modern dan strategis. <b>Cluster Nerin @ Astera,</b> Rumah
            Klaia Tipe 5x12 dengan konsep ruangan "open plan living room".
            <b>Rumah Khione</b> yang akan segera dirilis pada Q2 2024, Rumah
            Maira dengan harga jual Rp. 1,5 milyaran*, Semua cluster ini
            menawarka hunian yang modern, strategis, dan lengkap dengan
            fasilitas yang memadai, serta harga yang bervariatif tergantung tipe
            unit yang dipilih.
          </div>
        </div>
        <div className="clusterbutton">
          <div className="nerin">
            <a href="#nerin">
              <button className="button1">
                <FontAwesomeIcon icon={faHouse} /> &nbsp; Nerin
              </button>
            </a>
          </div>
          <div className="khione">
            <a href="#khione">
              <button className="button">
                <FontAwesomeIcon icon={faHouse} />
                &nbsp; Khione
              </button>
            </a>
          </div>
        </div>
        <div className="keterangan"> Start From 6 Juta/Bullan Promo ALL IN</div>
      </div>
    </div>
  );
};

export default produk;
