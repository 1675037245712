import Nyx1 from "./nyx (1).webp";
import Nyx2 from "./nyx (2).webp";
import Nyx3 from "./nyx (3).webp";
import Nyx4 from "./nyx (4).webp";
import Nyx5 from "./nyx (5).webp";
import Nyx6 from "./nyx (6).webp";
import Nyx7 from "./nyx (7).webp";
import Nyx8 from "./nyx (8).webp";
import Nyx9 from "./nyx (9).webp";
import Nyx10 from "./nyx (10).webp";
import Nyx11 from "./nyx (11).webp";
import Nyx12 from "./nyx (12).webp";
import Nyx13 from "./nyx (13).webp";
import Nyx14 from "./nyx (14).webp";
import Nyx15 from "./nyx (15).webp";

const nyximages = [
  Nyx1,
  Nyx2,
  Nyx3,
  Nyx4,
  Nyx5,
  Nyx6,
  Nyx7,
  Nyx8,
  Nyx9,
  Nyx10,
  Nyx11,
  Nyx12,
  Nyx13,
  Nyx14,
  Nyx15,
];

export default nyximages;
