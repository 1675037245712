import React from "react";
import "./khione.scss";
import logokhione from "../../asset/khione-logo.webp";
import nyximages from "../../asset/produk/khione/nyx/nyx";
import elyoneimages from "../../asset/produk/khione/elyone/elyone";
import {
  faBed,
  faHouse,
  faLocationDot,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const khione = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const khione1 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282114609687&text=Halo+William%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Nyx)%20http://marketing-giantaraserpongcity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const khione2 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282114609687&text=Halo+William%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(elyone)%20http://marketing-giantaraserpongcity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="khione1">
      <div id="khione" className="container-khione">
        <div className="header-khione">
          <div className="judul-khione">
            <img className="logok" src={logokhione} alt="logo" />
          </div>
        </div>

        <div className="produk-khione">
          <div className="kartu1">
            <Slider {...settings}>
              {nyximages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt="foto"
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">Nyx</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Serpong
                  City
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">14</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 98m²</span>
              <FontAwesomeIcon icon={faHouse} />
              <span> : 126m²</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 4</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 3</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={khione1} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
          <div className="kartu1">
            <Slider {...settings}>
              {elyoneimages.map((image, index) => (
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt="foto"
                />
              ))}
            </Slider>
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">Elyone</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} /> Serpong
                  City
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">19</div>
                    <div className="ket">Juta/Bulan</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridspek">
              <FontAwesomeIcon icon={faMaximize} />
              <span> : 128m²</span>
              <FontAwesomeIcon icon={faHouse} />
              <span> : 175m²</span>
              <FontAwesomeIcon icon={faBed} />
              <span>: 4+1</span>
              <FontAwesomeIcon icon={faShower} />
              <span>: 4+1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={khione2} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default khione;
