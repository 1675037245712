import Giantaral51 from "./giantaral5 (1).webp";
import Giantaral52 from "./giantaral5 (2).webp";
import Giantaral53 from "./giantaral5 (3).webp";
import Giantaral54 from "./giantaral5 (4).webp";
import Giantaral55 from "./giantaral5 (5).webp";
import Giantaral56 from "./giantaral5 (6).webp";
import Giantaral57 from "./giantaral5 (7).webp";
import Giantaral58 from "./giantaral5 (8).webp";
import Giantaral59 from "./giantaral5 (9).webp";

const giantaral5images = [
  Giantaral51,
  Giantaral52,
  Giantaral53,
  Giantaral54,
  Giantaral55,
  Giantaral56,
  Giantaral57,
  Giantaral58,
  Giantaral59,
];

export default giantaral5images;
