import Fasilitas1 from "./fasilitas (1).webp";
import Fasilitas2 from "./fasilitas (2).webp";
import Fasilitas3 from "./fasilitas (3).webp";
import Fasilitas4 from "./fasilitas (4).webp";
import Fasilitas5 from "./fasilitas (5).webp";
import Fasilitas6 from "./fasilitas (6).webp";
import Fasilitas7 from "./fasilitas (7).webp";
import Fasilitas8 from "./fasilitas (8).webp";
import Fasilitas9 from "./fasilitas (9).webp";

const fasilitasimages = [
  Fasilitas1,
  Fasilitas2,
  Fasilitas3,
  Fasilitas4,
  Fasilitas5,
  Fasilitas6,
  Fasilitas7,
  Fasilitas8,
  Fasilitas9,
];

export default fasilitasimages;
